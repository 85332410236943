import { forwardRef } from 'react';
import styles from './styles.module.css';

export type Props = {
  children?: React.ReactNode;
  ariaLabel?: string;
  collapsed?: boolean;
  role?: string;
};

export const DropdownItem = forwardRef<HTMLDivElement, Props>(
  ({ collapsed = false, ...rest }, ref) => {
    const classNames = collapsed
      ? `${styles.item} ${styles.collapsed}`
      : styles.item;
    return <div ref={ref} className={classNames} {...rest} />;
  }
);

DropdownItem.displayName = 'Dropdown.Item';
