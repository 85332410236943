import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Dropdown } from '@/components/new/Dropdown';
import { Text } from '@/components/new/Text';
import { CurrentRequestStatus, useCurrentOfficeMemberQuery } from '@/graphql';
import { MenuLink } from './MenuLink';
import { useLogout } from '@/hooks';

const LogoutButton = styled.button``;

export const UserMenu = (): JSX.Element | null => {
  const { logout } = useLogout();
  const { data, error, refetch } = useCurrentOfficeMemberQuery();

  //  When failed to get data, refetch is required to avoid null data
  // 例） 新規事業所 → 規約同意(null) → 注意事項(null) → 申請step(null)
  const router = useRouter();
  useEffect(() => {
    const refetchCurrentOfficeMember = () => {
      if (!data) refetch();
    };
    router.events.on('routeChangeComplete', refetchCurrentOfficeMember);
    return () => {
      router.events.off('routeChangeComplete', refetchCurrentOfficeMember);
    };
  }, [data]);

  if (error || !data) {
    // TODO: Error Notificatiop
    return null;
  }

  const { currentOfficeMember } = data;
  const isWalletOpened =
    data.currentOffice.currentRequestStatus ===
    CurrentRequestStatus.ActivatedCard;
  const flagShipUrl = process.env.NEXT_PUBLIC_FLAGSHIP_URL;
  return (
    <Dropdown.Root
      toggleLabel={currentOfficeMember.displayName}
      placement="right"
      width="312px"
    >
      <Dropdown.Block>
        <Dropdown.Item>
          <Text>{currentOfficeMember.displayName}</Text>
        </Dropdown.Item>
      </Dropdown.Block>
      <Dropdown.Block>
        <Dropdown.ActionItem>
          <MenuLink
            href={isWalletOpened ? '/settings/notifications' : `${flagShipUrl}`}
            target={isWalletOpened ? undefined : '_blank'}
          >
            通知設定
          </MenuLink>
        </Dropdown.ActionItem>
        <Dropdown.ActionItem>
          <MenuLink
            href={`${process.env.NEXT_PUBLIC_MFID_URL}/me`}
            target="_blank"
          >
            ユーザー設定
          </MenuLink>
        </Dropdown.ActionItem>
        <Dropdown.ActionItem>
          <LogoutButton type="button" onClick={logout}>
            ログアウト
          </LogoutButton>
        </Dropdown.ActionItem>
      </Dropdown.Block>
    </Dropdown.Root>
  );
};
