import { createContext, useMemo, useState } from 'react';

export type RouteHistoryContextType = {
  routeHistory: string[];
  pushRoute: (route: string) => void;
  popRoute: () => void;
};

export const RouteHistoryContext = createContext<RouteHistoryContextType>({
  routeHistory: [],
  pushRoute: () => {
    // This is a placeholder function that will be overridden by the provider
  },
  popRoute: () => {
    // This is a placeholder function that will be overridden by the provider
  },
});

export const RouteHistoryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [routeHistory, setRouteHistory] = useState<string[]>([]);

  const value = useMemo(() => {
    return {
      routeHistory: routeHistory,
      pushRoute: (route: string) => setRouteHistory([...routeHistory, route]),
      popRoute: () => setRouteHistory(routeHistory.slice(0, -1)),
    };
  }, [routeHistory]);

  return (
    <RouteHistoryContext.Provider value={value}>
      {children}
    </RouteHistoryContext.Provider>
  );
};
