import { useRouter } from 'next/router';
import { useRegistrationPageState } from '@/contexts/RegistrationProvider';

export const useGoToStepsOrConfirmation = (): VoidFunction => {
  const router = useRouter();
  const { registrationPageState, setFieldsState } = useRegistrationPageState();
  const isRegistrationIndividual = /^\/registration\/individual/.test(
    router.pathname
  );

  const goToStepsOrConfirmation = (): void => {
    if (!registrationPageState.isFromConfirmation) {
      router.push(
        isRegistrationIndividual
          ? '/registration/individual'
          : '/registration/corporate'
      );
    } else {
      router.push(
        isRegistrationIndividual
          ? '/registration/individual/confirmation'
          : '/registration/corporate/confirmation'
      );
      setFieldsState({ isFromConfirmation: false });
    }
  };

  return goToStepsOrConfirmation;
};
