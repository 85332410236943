import { createContext, useMemo, useState } from 'react';

type HowToUseStorylaneProviderProps = {
  children?: React.ReactNode;
};
export type ContextProps = {
  isOpen: boolean;
  showStorylane: () => void;
  hideStorylane: () => void;
};
export const HowToUseStorylaneContext = createContext<ContextProps>({
  isOpen: false,
  showStorylane: () => {
    // This is a placeholder function that will be overridden by the provider
  },

  hideStorylane: () => {
    // This is a placeholder function that will be overridden by the provider
  },
});
export const HowToUseStorylaneProvider = ({
  children,
}: HowToUseStorylaneProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const showStorylane = () => setIsOpen(true);
  const hideStorylane = () => setIsOpen(false);
  const value = useMemo(
    () => ({
      isOpen,
      showStorylane,
      hideStorylane,
    }),
    [isOpen]
  );
  return (
    <HowToUseStorylaneContext.Provider value={value}>
      {children}
    </HowToUseStorylaneContext.Provider>
  );
};
