import styled from 'styled-components';
import { HowToUseStorylaneModal } from '../HowToUseStorylaneModal';
import { Sidebar } from '../Sidebar';

const headerHeight = '40px';
const siderbarWidth = '130px';

export type WithSidebarLayoutProps = {
  children: React.ReactNode;
};

const InternalLayout = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: ${siderbarWidth} auto;
  grid-template-rows: auto;
`;

const Scrollable = styled.div`
  flex: 1;
  height: calc(100vh - ${headerHeight});
  overflow-y: auto;
`;

const Main = styled.main`
  min-height: calc(100vh - ${headerHeight});
`;

export const WithSidebarLayout = ({
  children,
}: WithSidebarLayoutProps): JSX.Element => {
  return (
    <InternalLayout>
      <Sidebar />
      <HowToUseStorylaneModal />
      <Scrollable>
        <Main>{children}</Main>
      </Scrollable>
    </InternalLayout>
  );
};
