import { forwardRef } from 'react';
import styles from './styles.module.css';

type Props = {
  children?: React.ReactNode;
  collapsed?: boolean;
  role?: string;
};

export const DropdownBlock = forwardRef<HTMLDivElement, Props>(
  ({ collapsed = false, ...props }, ref) => {
    const classNames = collapsed
      ? `${styles.block} ${styles.collapsed}`
      : `${styles.block}`;
    return <div ref={ref} className={classNames} {...props} />;
  }
);

DropdownBlock.displayName = 'Dropdown.Block';
