import { ApolloProvider } from '@apollo/client';
import { DefaultBodyType, MockedRequest } from 'msw';
import App, { AppContext } from 'next/app';
import { useRouter } from 'next/router';
import { destroyCookie, parseCookies } from 'nookies';
import { useEffect, useState } from 'react';
import { Logger } from '@/components/screen/Logger';
import { BaseLayout } from '@/components/screen/layouts/BaseLayout';
import { TagManager } from '@/components/screen/tagManager';
import { AppsBanner } from '@/components/ui/AppsBanner';
import { Chatbot } from '@/components/ui/Chatbot';
import { GlobalNotification } from '@/components/ui/GlobalNotification';
import { initializeApollo, useApollo } from '@/lib/apollo';
import { KEY_LAST_LOGIN_OFFICE } from '@/lib/cookie';
import { hideSideBar } from '@/lib/layout';
import { AppPropsWithLayout } from '@/lib/page';
import { initDatadogRum } from '@/lib/performance/initDatadogRum';
import { skipRedirectToThisPath } from '@/lib/redirect';
import { hasInvalidOfficeIdError } from '@/hooks/useApiError';
import {
  CurrentIdentificationCodeDocument,
  CurrentIdentificationCodeQuery,
  CurrentUserIdDocument,
  CurrentUserIdQuery,
} from '@/graphql';
import { CurrentOfficeProvider } from '@/contexts/CurrentOfficeProvider';
import { GlobalMessageProvider } from '@/contexts/GlobalMessageProvider';
import { GlobalNotificationProvider } from '@/contexts/GlobalNotificationProvider';
import { HowToUseStorylaneProvider } from '@/contexts/HowToUseStorylaneProvider';
import { RegistrationPageStateProvider } from '@/contexts/RegistrationProvider';
import { RouteEventHandler } from '@/contexts/RouteEventHandler';
import '@/styles/globals.css';
import { RouteHistoryProvider } from '@/contexts/RouteHistoryProvider';

const MyApp = ({ Component, pageProps }: AppPropsWithLayout): JSX.Element => {
  const cookies = parseCookies();
  const client = useApollo(
    pageProps.initialApolloState,
    cookies[KEY_LAST_LOGIN_OFFICE]
  );
  const router = useRouter();
  const showSidebar = !hideSideBar(router.pathname);

  const getLayout =
    Component.getLayout ??
    ((page) => (
      <BaseLayout sidebar={showSidebar}>
        <GlobalNotification showSidebar={showSidebar} />
        {page}
      </BaseLayout>
    ));

  useEffect(() => {
    initDatadogRum();
  }, []);

  return (
    <CurrentOfficeProvider>
      <GlobalNotificationProvider>
        <GlobalMessageProvider>
          <RouteHistoryProvider>
            <RouteEventHandler>
              <ApolloProvider client={client}>
                <Logger user={pageProps.user}>
                  <HowToUseStorylaneProvider>
                    {/* REVIEW: This provider should be in only registration dir */}
                    <RegistrationPageStateProvider>
                      <TagManager
                        identificationCode={pageProps.identificationCode}
                      >
                        {/* SSRさせないと smart app bannerが動作しないのでこの位置 */}
                        <AppsBanner />

                        {getLayout(<Component {...pageProps} />)}
                      </TagManager>
                      <Chatbot />
                    </RegistrationPageStateProvider>
                  </HowToUseStorylaneProvider>
                </Logger>
              </ApolloProvider>
            </RouteEventHandler>
          </RouteHistoryProvider>
        </GlobalMessageProvider>
      </GlobalNotificationProvider>
    </CurrentOfficeProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  // SSR時のみ以降の処理を実行する(ブラウザ側は実行しない)
  if (typeof window !== 'undefined') return { ...appProps };

  const { ctx, Component } = appContext;
  const authNotRequired =
    Component.displayName &&
    [
      'ErrorPage',
      'Error404Page',
      'Error403Page',
      'Error500Page',
      'HealthzPage',
      'LoginPage',
      'AuthPage',
      'AuthCallbackPage',
      'VerificationPage',
      'RegisterPage',
      'CardSupportPage',
      'SsoPage',
    ].includes(Component.displayName);
  if (authNotRequired) return { ...appProps };

  const { lastLoginOffice: officeId } = parseCookies(ctx);
  const { query, cache } = initializeApollo({ ctx, officeId });
  const { pathname, asPath } = ctx;

  let user: CurrentUserIdQuery | undefined = undefined;
  try {
    user = (
      await query<CurrentUserIdQuery>({
        query: CurrentUserIdDocument,
      })
    ).data;
  } catch (e) {
    // Escape SSO page as it is accessed by users of external services
    if (pathname === 'SsoPage') return;

    const isInvalidOfficeIdError = hasInvalidOfficeIdError(e);

    // NOTE: When X-Office-Id is invalid, we delete the invalid cookie and make redirect to /offices.
    if (isInvalidOfficeIdError) {
      destroyCookie(ctx, KEY_LAST_LOGIN_OFFICE, { path: '/' });
      ctx.res?.writeHead(302, { Location: '/offices' });
      ctx.res?.end();
    } else {
      // Redirect to login page if currentUser could not be fetched during SSR
      const loginPath = skipRedirectToThisPath(pathname)
        ? '/login'
        : `/login?redirectPath=${asPath}`;
      ctx.res?.writeHead(302, { Location: loginPath });
      ctx.res?.end();
    }
  }

  // for google tag manager datalayer
  let identificationCode: CurrentIdentificationCodeQuery | undefined =
    undefined;
  try {
    identificationCode = (
      await query<CurrentIdentificationCodeQuery>({
        query: CurrentIdentificationCodeDocument,
      })
    ).data;
  } catch (e) {
    // do nothing
  }

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      initialApolloState: cache.extract(),
      user,
      identificationCode,
    },
  };
};

// MSW
const useMsw = process.env.NEXT_PUBLIC_CLOUD_WALLET_API_MOCK === 'true';
const isServer = typeof window === 'undefined';
const startOption = {
  onUnhandledRequest(req: MockedRequest<DefaultBodyType>) {
    if (
      req.url.host === 'telemetry.nextjs.org' ||
      req.url.host === 'fonts.gstatic.com' ||
      req.url.pathname.startsWith('/_next/')
    )
      return;
    console.warn(
      `[MSW]Found an unhandled request. ${req.method} ${
        req.url.href
      } ${JSON.stringify(req.body)}`
    );
  },
};

if (useMsw && isServer)
  // サーバーサイドは起動してから次の処理に進むため、起動を待たなくてよい
  import('@/tests/mocks/graphql/server').then(({ server }) =>
    server.listen(startOption)
  );

const MswClientApp = (props: AppPropsWithLayout): JSX.Element | null => {
  const [mswReady, setMswReady] = useState(false);

  if (!mswReady) {
    // クライアントサイドは非同期でMSW起動するため、起動を待ってからコンポーネントをレンダリングする
    import('@/tests/mocks/graphql/browser').then(({ worker }) =>
      worker.start(startOption).then(() => {
        setMswReady(true);
      })
    );
    return null;
  }

  return <MyApp {...props} />;
};

export default useMsw && !isServer ? MswClientApp : MyApp;
