import { useGlobalMessage } from './useGlobalMessage';
import { useLogger } from './useLogger';

type HandleUnknownErrorFunction = (error: unknown) => void;

export const useHandleUnknownError = (): HandleUnknownErrorFunction => {
  const { setErrorMessage } = useGlobalMessage();
  const { logError } = useLogger();

  const handleUnknownError: HandleUnknownErrorFunction = (error) => {
    if (error instanceof Error) {
      logError(error.name, error.message, error.stack);
    } else {
      logError('UnknownError', error);
    }

    setErrorMessage(
      '予期せぬエラーが発生しました。繰り返し同様の操作をしても改善されない場合はお問い合わせください。'
    );
  };

  return handleUnknownError;
};
