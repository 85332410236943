import { forwardRef } from 'react';
import { CaretIcon } from '../../icon/CaretIcon';
import { useDropdown } from '../DropdownContext';
import styles from './styles.module.css';

type Props = {
  children: React.ReactNode;
};

export const DropdownToggleButton = forwardRef<HTMLButtonElement, Props>(
  ({ children }, ref) => {
    const { isActive, setIsActive } = useDropdown();
    const handleClick = () => {
      setIsActive(!isActive);
    };
    return (
      <button
        className={styles.button}
        aria-haspopup
        aria-expanded={isActive}
        onClick={handleClick}
        ref={ref}
      >
        <span className={styles.inner}>
          {children}
          <CaretIcon size={24} rotation={isActive ? 'up' : 'down'} />
        </span>
      </button>
    );
  }
);

DropdownToggleButton.displayName = 'Dropdown.ToggleButton';
