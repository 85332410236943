import styled from 'styled-components';
import { NavGroup } from './NavGroup';
import { NavGroupItemLink } from './NavGroupItemLink';
import { NavItemLink } from './NavItemLink';

const NavList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const StyledSidebar = styled.div`
  width: 130px;
  height: 100%;
  background-color: #313740;
`;

export type SidebarProps = {
  children?: React.ReactNode;
};

const SidebarBase = ({ children }: SidebarProps) => (
  <StyledSidebar>
    <nav>
      <NavList>{children}</NavList>
    </nav>
  </StyledSidebar>
);

export const Sidebar = Object.assign(SidebarBase, {
  NavItemLink,
  NavGroup,
  NavGroupItemLink,
});
