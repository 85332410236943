import { DropdownActionItem } from './DropdownActionItem';
import { DropdownBlock } from './DropdownBlock';
import { DropdownItem } from './DropdownItem';
import { DropdownRoot } from './DropdownRoot';

export const Dropdown = Object.assign(
  {},
  {
    Root: DropdownRoot,
    Block: DropdownBlock,
    Item: DropdownItem,
    ActionItem: DropdownActionItem,
  }
);
