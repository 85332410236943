export const isEmpty = (str?: string): boolean => !str || str.length === 0;
export const isNotEmpty = (str?: string): boolean => !isEmpty(str);

export const isBlank = (str?: string): boolean => isEmpty(str?.trim());
export const isNotBlank = (str?: string): boolean => !isBlank(str);

export const generateNonceString = (length: number) => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const formatIdentificationCode = (
  code: string,
  delimiter = '-'
): string => {
  if (code.length <= 4) return code;
  return `${code.slice(0, 4)}${delimiter}${code.slice(4)}`;
};
