import { ComponentProps } from 'react';
import styled from 'styled-components';
import { useDropdown } from '@/components/new/Dropdown';
import { DropdownToggleButton } from '@/components/new/Dropdown/DropdownToggleButton';
import { Tooltip } from '@/components/new/Tooltip';
import { HelpIcon } from '@/components/new/icon/HelpIcon';
import { CampaignIcon } from './CampaignIcon';

type DropdownToggleButtonProps = ComponentProps<typeof DropdownToggleButton>;

type Props = Omit<DropdownToggleButtonProps, 'children'> & {
  message: string;
  type: 'help' | 'news';
};

const StyledIconButton = styled.button.attrs<Props>(({ isOpen }) => ({
  'aria-expanded': isOpen,
  'aria-haspopup': true,
}))`
  && {
    background-color: transparent;
    border: 0;
    line-height: 1;
    user-select: none;
    appearance: none;
    cursor: pointer;
    svg {
      color: #7c8291;
    }
    &:hover,
    &:active,
    &:focus {
      svg {
        color: #3b7de9;
      }
    }
  }
`;

export const IconButton = ({ message, type, ...rest }: Props): JSX.Element => {
  const { isActive, setIsActive } = useDropdown();
  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <Tooltip content={message} side="bottom">
      <StyledIconButton
        onClick={handleClick}
        aria-expanded={isActive}
        {...rest}
      >
        {type === 'help' ? (
          <HelpIcon size={24} color="#7C8291" />
        ) : (
          <CampaignIcon />
        )}
      </StyledIconButton>
    </Tooltip>
  );
};
