import { ApolloError } from '@apollo/client';
import { useCurrentOfficeTenantTypeQuery, TenantTypeEnum } from '@/graphql';
import { useApiError } from './useApiError';

export const useTenantType = (): {
  isCorporate: boolean;
  loading: boolean;
  error: undefined | ApolloError;
} => {
  const { handleQueryError } = useApiError();
  const { data, loading, error } = useCurrentOfficeTenantTypeQuery({
    onError: handleQueryError,
  });
  const isCorporate =
    data?.currentOffice.tenantType === TenantTypeEnum.Corporate;

  return { isCorporate, loading, error };
};
