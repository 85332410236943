import NextLink, { LinkProps } from 'next/link';
import { PropsWithChildren } from 'react';
import styles from './styles.module.css';

type Props = PropsWithChildren<LinkProps> & {
  target?: string;
};

export const MenuLink = ({ target, children, ...rest }: Props) => (
  <NextLink {...rest} passHref>
    <a {...{ target }} className={styles.anchor}>
      {children}
    </a>
  </NextLink>
);
