import { Notification } from '@/components/new/Notification';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';

type GlobalNotificationProps = {
  showSidebar: boolean;
};

export const GlobalNotification = ({
  showSidebar,
}: GlobalNotificationProps): JSX.Element => {
  const { notification, isVisible, type, hideGlobalNotification } =
    useGlobalNotification();

  return (
    <Notification
      isOpen={isVisible}
      color={type}
      align="center"
      showSidebar={showSidebar}
      onClickClose={hideGlobalNotification}
    >
      {notification}
    </Notification>
  );
};
