import { CookieSerializeOptions } from 'next/dist/server/web/types';
import { setCookie, destroyCookie } from 'nookies';

export const KEY_OAUTH_STATE = 'state';
export const KEY_LAST_LOGIN_OFFICE = 'lastLoginOffice';
export const KEY_REDIRECT_PATH = 'redirectPath';
export const KEY_TENANT_UID = 'tenantUid';
export const SETTINGS_OAUTH_STATE: CookieSerializeOptions = {
  maxAge: 15 * 60, // 15minutes
  path: '/',
  secure: true,
};
export const SETTINGS_REDIRECT_PATH: CookieSerializeOptions = {
  maxAge: 15 * 60, // 15minutes
  path: '/',
  secure: true,
};
export const SETTINGS_LAST_LOGIN_OFFICE: CookieSerializeOptions = {
  maxAge: 24 * 60 * 60, // 1day
  path: '/',
  secure: true,
};
export const SETTINGS_TENANT_UID: CookieSerializeOptions = {
  maxAge: 15 * 60, // 15minutes
  path: '/',
  secure: true,
};

export const setLoginCookie = (officeId: string): void => {
  setCookie(null, KEY_LAST_LOGIN_OFFICE, officeId, SETTINGS_LAST_LOGIN_OFFICE);
};

export const setOauthStateCookie = (value: string): void => {
  setCookie(null, KEY_OAUTH_STATE, value, SETTINGS_OAUTH_STATE);
};

export const setRedirectPathCookie = (value: string): void => {
  setCookie(null, KEY_REDIRECT_PATH, value, SETTINGS_REDIRECT_PATH);
};

export const setTenantUidCookie = (tenantUid: string): void => {
  setCookie(null, KEY_TENANT_UID, tenantUid, SETTINGS_TENANT_UID);
};

export const clearCookie = (): void => {
  destroyCookie(null, KEY_LAST_LOGIN_OFFICE, { path: '/' });
  destroyCookie(null, KEY_OAUTH_STATE, { path: '/' });
  destroyCookie(null, KEY_REDIRECT_PATH, { path: '/' });
  destroyCookie(null, KEY_TENANT_UID, { path: '/' });
};
