import { ReactNode } from 'react';
import styles from './styles.module.css';

type RootProps = {
  children?: ReactNode;
};
const Root = ({ children }: RootProps): JSX.Element => {
  return <header className={styles.header}>{children}</header>;
};

const Name = ({ children }: { children?: React.ReactNode }): JSX.Element => (
  <div className={styles.name}>{children}</div>
);
Name.displayName = 'Header.Name';

const Item = ({ children }: { children?: React.ReactNode }): JSX.Element => (
  <div className={styles.item}>{children}</div>
);
Item.displayName = 'Header.Item';

export const Header = Object.assign(
  {},
  {
    Root,
    Name,
    Item,
  }
);
