import { useState } from 'react';
import { dataLayerPush, DataLayerObject } from '@/lib/gtm';
import { CurrentIdentificationCodeQuery } from '@/graphql';

export const useTrackingData = (): {
  trackingData: DataLayerObject;
  setTrackingData: (data: CurrentIdentificationCodeQuery) => void;
} => {
  const [data, setData] = useState<DataLayerObject>({});

  const setTrackingData = ({
    currentUser,
    currentOffice,
  }: CurrentIdentificationCodeQuery): void => {
    setData((prevState) => {
      const current = {
        ...prevState,
        user_id: currentUser.identificationCode || '',
        office_identification_code: currentOffice.identificationCode,
        office_type: currentOffice.tenantType,
      };

      dataLayerPush(current);
      return current;
    });
  };

  return { trackingData: data, setTrackingData };
};
