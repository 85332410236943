import { ApolloError } from '@apollo/client';
import { useCurrentOfficeMemberRoleQuery, RoleEnum } from '@/graphql';
import { useApiError } from './useApiError';

export const useRole = (): {
  role: RoleEnum | undefined;
  loading: boolean;
  error: undefined | ApolloError;
} => {
  const { handleQueryError } = useApiError();
  const { data, loading, error } = useCurrentOfficeMemberRoleQuery({
    onError: handleQueryError,
  });

  const role = data?.currentOfficeMember.role;

  return { role, loading, error };
};
