import styled from 'styled-components';
import { Dropdown } from '@/components/new/Dropdown';
import { Text } from '@/components/new/Text';
import { formatIdentificationCode } from '@/lib/string';
import { CurrentRequestStatus, RoleEnum, useOfficeMenuQuery } from '@/graphql';
import { MenuLink } from './MenuLink';

const Box = styled.div`
  margin-bottom: 2px;
`;
export const OfficeMenu = (): JSX.Element | null => {
  const { data, error } = useOfficeMenuQuery();
  if (error || !data) {
    // TODO: エラー通知
    return null;
  }
  const { currentOffice, currentOfficeMember } = data;

  return (
    <Dropdown.Root
      toggleLabel={currentOffice.name}
      placement="right"
      width="312px"
    >
      <Dropdown.Block>
        <Dropdown.Item>
          <Box>{currentOffice.name}</Box>
          <Text color="notes">
            事業者番号:
            {formatIdentificationCode(currentOffice.identificationCode)}
          </Text>
        </Dropdown.Item>
        {currentOffice.currentRequestStatus ===
          CurrentRequestStatus.ActivatedCard &&
          currentOfficeMember.role === RoleEnum.Admin && (
            <Dropdown.ActionItem>
              <MenuLink href="/settings/office">事業者設定</MenuLink>
            </Dropdown.ActionItem>
          )}
      </Dropdown.Block>
      <Dropdown.Block>
        <Dropdown.ActionItem>
          <MenuLink href="/offices">事業者切替</MenuLink>
        </Dropdown.ActionItem>
      </Dropdown.Block>
    </Dropdown.Root>
  );
};
