import iconClose from '@images/icons/icon_close_for_apps_banner.svg';
import walletIcon from '@images/wallet_icon_for_apps_banner.png';
import NextHead from 'next/head';
import { useEffect, useState } from 'react';
import { isMobile, isAndroid } from 'react-device-detect';
import styled from 'styled-components';
import { Image } from '@/components/ui/Image';

const AppsBannerContainer = styled.aside`
  // モバイルでしか表示しないので、モバイル用のスタイルのみ
  // mfc_portal_webのものを持ってきているので、クラス形式でcssを当てている（ちょっと変えてはいる）
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: inherit;
  position: fixed;
  top: 0;
  z-index: var(--z-index-app-banner);
  .apps-banner-contents {
    display: grid;
    grid-template-columns: 22px 36px 1fr 48px;
    max-width: 1200px;
    margin: 0 auto;
  }
  .apps-banner-logo {
    display: block;
    grid-column: 2;
    grid-row: 1;
    align-self: center;
    width: 28px;
    height: 28px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .apps-banner-info {
    grid-column: 3;
    grid-row: 1;
    align-self: center;
    font-size: 10px;
  }
  .apps-banner-name {
    color: #fff;
    font-size: inherit;
    margin: 0;
  }
  .apps-banner-details {
    color: #7f8598;
    margin: 0;
  }
  .apps-banner-store {
    color: #63697f;
  }
  .apps-banner-link {
    display: inline-flex;
    grid-column: 4;
    grid-row: 1;
    align-items: center;
    align-self: center;
    justify-content: center;
    min-height: 28px;
    padding: 6px 14px;
    border-radius: 999em;
    background-color: #0054ac;
    color: #fff;
    font-size: 10px;
    font-weight: 500;
    font-weight: $MFC-FONT-WEIGHT-MEDIUM;
    text-decoration: none;
  }
  .apps-banner-close {
    display: inline-block;
    grid-column: 1;
    grid-row: 1;
    align-self: center;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    color: inherit;
    text-align: left;
    width: 14px;
    img {
      height: auto;
      width: 100%;
    }
  }
`;

export const AppsBanner = (): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  const sessionStorageKey = 'android-apps-banner-status';

  useEffect(() => {
    const hasNotClosed =
      window.sessionStorage.getItem(sessionStorageKey) !== 'hasClosed';
    setIsVisible(isAndroid && isMobile && hasNotClosed);
  }, []);

  const handleClose = (event: React.TouchEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.sessionStorage.setItem(sessionStorageKey, 'hasClosed');
    setIsVisible(false);
  };

  return (
    <>
      {/* for iOS */}
      <NextHead>
        <meta
          content="app-id=1573194490, affiliate-data=pt=1944207&amp;ct=wep_app&amp;mt=8"
          name="apple-itunes-app"
        />
      </NextHead>

      {/* for android */}
      {isVisible && (
        <AppsBannerContainer>
          <div className="apps-banner-contents">
            <div className="apps-banner-logo">
              <Image
                src={walletIcon}
                alt="マネーフォワード Pay for Business"
                width={28}
                height={28}
              />
            </div>
            <div className="apps-banner-info">
              <h2 className="apps-banner-name">
                マネーフォワード Pay for Business
              </h2>
              <p className="apps-banner-details">
                <span className="apps-banner-provider">
                  株式会社マネーフォワード
                </span>
              </p>
            </div>
            <a
              className="apps-banner-link"
              href="https://play.google.com/store/apps/details?id=com.moneyforward.bizpay"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              表示
            </a>
            <button className="apps-banner-close" onTouchStart={handleClose}>
              <Image
                className="apps-banner-close-icon js-close-apps-banner"
                src={iconClose}
                alt="閉じる"
                width={14}
                height={14}
              />
            </button>
          </div>
        </AppsBannerContainer>
      )}
    </>
  );
};
