import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import { Dropdown } from '@/components/new/Dropdown';
import {
  CurrentRequestStatus,
  TenantTypeEnum,
  useHelpMenuQuery,
} from '@/graphql';
import { HowToUseStorylaneButton } from './HowToUseStorylaneButton';
import { IconButton } from './IconButton';
import { MenuLink } from './MenuLink';

export const HelpMenu = (): JSX.Element => {
  const router = useRouter();
  // should skip query in below paths even though user might have lastLoginOffice
  const skipPaths = [
    '/403',
    '/404',
    '/500',
    '/login',
    '/login/redirect',
    '/offices',
    '/offices/register',
    '/registration/terms',
    '/verification',
  ];
  const isSkipPage = skipPaths.some((path) => router.pathname === path);
  const officeId = parseCookies().lastLoginOffice;
  const hasOfficeId = !!officeId;
  const { data } = useHelpMenuQuery({
    fetchPolicy: 'cache-and-network',
    skip: isSkipPage || !hasOfficeId,
    onError: () => {
      // do nothing since just hide link to inquiry page
    },
  });
  const isWalletOpened =
    data?.currentOffice.currentRequestStatus ===
    CurrentRequestStatus.ActivatedCard;
  const isCorporate =
    data?.currentOffice.tenantType === TenantTypeEnum.Corporate;
  const feedbackUrl = data?.currentUser.feedbackFormUrl;

  return (
    <Dropdown.Root
      width="235px"
      placement="right"
      anchor={<IconButton message="ヘルプ" type="help" />}
    >
      <Dropdown.Block>
        {/* After log-in office, before wallet activation, only corporate office */}
        {!isWalletOpened && isCorporate && (
          <Dropdown.ActionItem>
            <MenuLink href="/registration/corporate/invite">
              ユーザー招待
            </MenuLink>
          </Dropdown.ActionItem>
        )}
        {isWalletOpened && (
          <Dropdown.ActionItem>
            <HowToUseStorylaneButton />
          </Dropdown.ActionItem>
        )}
        <Dropdown.ActionItem>
          <MenuLink
            href="https://support.biz.moneyforward.com/biz-pay/"
            target="_blank"
          >
            サポートサイト
          </MenuLink>
        </Dropdown.ActionItem>
        {feedbackUrl && (
          <Dropdown.ActionItem>
            <MenuLink href={feedbackUrl} target="_blank">
              お問い合わせ
            </MenuLink>
          </Dropdown.ActionItem>
        )}
      </Dropdown.Block>
      <Dropdown.Block>
        <Dropdown.ActionItem>
          <MenuLink
            href="https://biz.moneyforward.com/biz-pay/agreement/"
            target="_blank"
          >
            利用規約
          </MenuLink>
        </Dropdown.ActionItem>
        <Dropdown.ActionItem>
          <MenuLink
            href="https://biz.moneyforward.com/biz-pay/settlement/"
            target="_blank"
          >
            資金決済法に基づく表示
          </MenuLink>
        </Dropdown.ActionItem>
        <Dropdown.ActionItem>
          <MenuLink
            href="https://corp.moneyforward.com/privacy/"
            target="_blank"
          >
            個人情報保護方針
          </MenuLink>
        </Dropdown.ActionItem>
        <Dropdown.ActionItem>
          <MenuLink
            href="https://corp.moneyforward.com/privacy/external-tools/"
            target="_blank"
          >
            外部送信ツールに関する公表事項
          </MenuLink>
        </Dropdown.ActionItem>
        <Dropdown.ActionItem>
          <MenuLink
            href="https://biz.moneyforward.com/biz-pay/agreement/point/"
            target="_blank"
          >
            ポイント規約
          </MenuLink>
        </Dropdown.ActionItem>
      </Dropdown.Block>
    </Dropdown.Root>
  );
};
