export const transformQueryResult = {
  iso8601Date: (original: string): string =>
    original.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1/$2/$3'),
  postalCode: (original: string): string =>
    original.replace(/(\d{3})(\d{4})/, '$1-$2'),
  localeString: (original: number): string =>
    isNaN(original) ? '' : original.toLocaleString(),
  tenThousandLocaleString: (value: number) => {
    const floored = isNaN(value) ? 0 : Math.floor(value / 10000);
    return floored.toLocaleString();
  },
  officeIdentificationCode: (original = '', delimiter = '-'): string => {
    if (original.length <= 4) return original;
    return `${original.slice(0, 4)}${delimiter}${original.slice(4)}`;
  },
};
