import { createContext, useMemo, useState } from 'react';
import { Office } from '@/graphql';

type CurrentOfficeContextType = {
  name?: Office['name'];
  setName: (name: Office['name']) => void;
  tenantType?: Office['tenantType'];
  setTenantType: (tenantType: Office['tenantType']) => void;
  ekycStatus?: Office['ekycStatus'];
  setEkycStatus: (ekycStatus: Office['ekycStatus']) => void;
};

export const CurrentOfficeContext = createContext<CurrentOfficeContextType>({
  name: undefined,
  setName: () => {
    // This is a placeholder function that will be overridden by the provider
  },
  tenantType: undefined,
  setTenantType: () => {
    // This is a placeholder function that will be overridden by the provider
  },
  ekycStatus: undefined,
  setEkycStatus: () => {
    // This is a placeholder function that will be overridden by the provider
  },
});

export const CurrentOfficeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentOffice, setCurrentOffice] = useState<
    Pick<CurrentOfficeContextType, 'name' | 'tenantType' | 'ekycStatus'>
  >({
    name: undefined,
    tenantType: undefined,
    ekycStatus: undefined,
  });

  const value = useMemo(() => {
    return {
      name: currentOffice.name,
      setName: (name: Office['name']) =>
        setCurrentOffice((prev) => ({ ...prev, name })),
      tenantType: currentOffice.tenantType,
      setTenantType: (tenantType: Office['tenantType']) =>
        setCurrentOffice((prev) => ({ ...prev, tenantType })),
      ekycStatus: currentOffice.ekycStatus,
      setEkycStatus: (ekycStatus: Office['ekycStatus']) =>
        setCurrentOffice((prev) => ({ ...prev, ekycStatus })),
    };
  }, [currentOffice]);

  return (
    <CurrentOfficeContext.Provider value={value}>
      {children}
    </CurrentOfficeContext.Provider>
  );
};
