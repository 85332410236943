import { datadogRum } from '@datadog/browser-rum';

const omitQuery = (url: string) => url.replace(/\?.*$/, '');

export const initDatadogRum = () => {
  if (typeof window === 'undefined') {
    return;
  }
  const applicationId = process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID;
  const clientToken = process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN;
  // NOTE: For Saving cost
  const envSessionSampleRate = Number(
    process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE
  );
  const sessionSampleRate = Number.isNaN(envSessionSampleRate)
    ? 100
    : envSessionSampleRate;
  if (!applicationId || !clientToken || !sessionSampleRate) {
    return;
  }

  const runtimeEnv = process.env.NODE_ENV;
  if (!['production', 'staging'].includes(runtimeEnv)) {
    return;
  }

  // NOTE: ignore setup when the landing page is for authn.
  const isIgnoredPaths = (url: string) =>
    ['/sso', '/auth'].some((path) => url.includes(path));
  if (isIgnoredPaths(window.location.href)) {
    return;
  }

  // NOTE: https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: 'bizpay',
    env: runtimeEnv,
    sessionSampleRate,
    // NOTE: Don't use Session Replay
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: false,
    trackLongTasks: false,
    defaultPrivacyLevel: 'mask',
    // NOTE: https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#modify-the-content-of-a-rum-event
    beforeSend: (event) => {
      event.view.url = omitQuery(event.view.url);
      if (event.type === 'error' && !!event.error.resource) {
        event.error.resource.url = omitQuery(event.view.url);
      }
      if (event.type === 'resource') {
        event.resource.url = omitQuery(event.view.url);
      }
      // NOTE: https://docs.datadoghq.com/real_user_monitoring/guide/browser-sdk-upgrade/#beforesend-return-type
      return true;
    },
  });
};
