import Image from 'next/image';
import { useContext } from 'react';
import styled from 'styled-components';
import { Dialog } from '@/components/new/Dialog';
import styles from './styles.module.css';
import crossCloseWhiteIcon from '@/assets/images/icons/crossCloseWhite.svg';
import { HowToUseStorylaneContext } from '@/contexts/HowToUseStorylaneProvider';

const StorylaneWrapper = styled.div({
  width: '768px', // 800px - 16px * 2
});

const Embed = styled.div({
  position: 'relative',
  paddingBottom: 'calc(54.66% + 27px)',
  width: '100%',
});

const StyledIframe = styled.iframe({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: '1px solid rgba(63, 95, 172, 0.35)',
  boxShadow: '0px 0px 18px rgba(26, 19, 72, 0.15)',
  borderRadius: '10px',
});

export const HowToUseStorylaneModal = () => {
  const { isOpen, hideStorylane } = useContext(HowToUseStorylaneContext);
  return (
    <Dialog open={isOpen}>
      <Dialog.Window
        size="large"
        transparent
        onInteractOutside={hideStorylane}
        onEscapeKeyDown={hideStorylane}
        onPointerDownOutside={hideStorylane}
      >
        <div className={styles.header}>
          <button
            aria-label="閉じる"
            onClick={hideStorylane}
            className={styles.transparent}
          >
            <Image src={crossCloseWhiteIcon} width={24} height={24} alt="" />
          </button>
        </div>
        <Dialog.Contents>
          <StorylaneWrapper>
            <Embed className="sl-embed">
              <StyledIframe
                className="sl-demo"
                src="https://moneyforward.storylane.io/share/ycs9mgg7utve"
                name="sl-embed"
                allow="fullscreen; camera; microphone"
              />
            </Embed>
          </StorylaneWrapper>
        </Dialog.Contents>
      </Dialog.Window>
    </Dialog>
  );
};
