import { ComponentProps, forwardRef, useMemo, useState } from 'react';
import { DropdownBody } from '../DropdownBody';
import { DropdownContext } from '../DropdownContext';
import { DropdownToggleButton } from '../DropdownToggleButton';
import styles from './styles.module.css';

type AnchorProps =
  | {
      anchor: JSX.Element;
      toggleLabel?: never;
    }
  | {
      anchor?: never;
      toggleLabel: string;
    };

type Props = {
  children?: React.ReactNode;
  className?: string;
} & ComponentProps<typeof DropdownBody> &
  AnchorProps;

export const DropdownRoot = forwardRef<HTMLDivElement, Props>(
  ({ anchor, children, toggleLabel, ...bodyProps }, ref) => {
    const [isActive, setIsActive] = useState(false);
    const handleClick = () => {
      setIsActive(false);
    };
    const value = useMemo(
      () => ({ isActive, setIsActive }),
      [isActive, setIsActive]
    );
    return (
      <DropdownContext.Provider value={value}>
        <div className={styles.root} ref={ref}>
          {anchor ?? <DropdownToggleButton>{toggleLabel}</DropdownToggleButton>}
          <DropdownBody {...bodyProps}>{children}</DropdownBody>
          <div
            className={styles.overlay}
            aria-hidden={!isActive}
            onClick={handleClick}
            aria-label="閉じる"
          />
        </div>
      </DropdownContext.Provider>
    );
  }
);

DropdownRoot.displayName = 'Dropdown.Root';
