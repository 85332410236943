import Script from 'next/script';
import { useEffect } from 'react';
import { gtmScript } from '@/lib/gtm';
import { CurrentIdentificationCodeQuery } from '@/graphql';
import { useTrackingData } from './hooks';

type TagManagerProps = {
  children: React.ReactNode;
  identificationCode?: CurrentIdentificationCodeQuery;
};

export const TagManager = ({
  children,
  identificationCode,
}: TagManagerProps): JSX.Element => {
  const { setTrackingData } = useTrackingData();

  // userのtracking dataの送信
  useEffect(() => {
    // push dataLayer when window was loaded
    if (identificationCode) {
      setTrackingData(identificationCode);
    }
  }, [identificationCode]);

  return (
    <>
      {/* Google Tag Manager */}
      <Script
        id="gtmScript"
        // load script after fetch user data
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: gtmScript,
        }}
      />
      {/* End Google Tag Manager */}
      {children}
    </>
  );
};
