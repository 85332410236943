import styles from './styles.module.css';

type IconProps = {
  size?: number;
  rotation?: keyof typeof styles;
};

export const CaretIcon = ({ size = 12, rotation = 'up' }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles[rotation]}
    >
      <g clipPath="url(#clip0_324_494)">
        <path d="M7 10L12 15L17 10H7Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_324_494">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
