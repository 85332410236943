import styles from './styles.module.css';

type IconProps = {
  color?: 'blue' | 'lightblue';
  size?: number;
  rotation?: keyof typeof styles;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const aspectRatio = 8 / 12;

export const ChevronIcon = ({
  color = 'blue',
  size = 12,
  rotation = 'up',
}: IconProps) => {
  return (
    <svg
      aria-label=""
      aria-hidden="true"
      width={size * aspectRatio}
      height={size}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles[rotation]}
    >
      <path
        d="M2 0L0.589996 1.41L5.17 6L0.589996 10.59L2 12L8 6L2 0Z"
        fill={color === 'blue' ? '#3B7DE9' : '#EAF0FF'}
      />
    </svg>
  );
};
